body.citigpa,
body.citigpa-dev {
  font-family: Citi-Sans-Text-Regular !important;
  font-weight: 400 !important;
}
body.citigpa .lmn-input,
body.citigpa .lmn-btn,
body.citigpa .lmn-select-button,
body.citigpa .lmn-menu,
body.citigpa .lmn-selection-control,
body.citigpa .lmn-dropdown-toggle,
body.citigpa-dev .lmn-input,
body.citigpa-dev .lmn-btn,
body.citigpa-dev .lmn-select-button,
body.citigpa-dev .lmn-menu,
body.citigpa-dev .lmn-selection-control,
body.citigpa-dev .lmn-dropdown-toggle {
  font-weight: 400 !important;
}
body.citigpa.icgds .lmn-modal,
body.citigpa-dev.icgds .lmn-modal {
  min-width: auto;
}
body.citigpa .lmn-font-overpass,
body.citigpa .lmn-h2,
body.citigpa .lmn-h3,
body.citigpa .lmn-h4,
body.citigpa .lmn-h5,
body.citigpa .lmn-h6,
body.citigpa .lmn-input,
body.citigpa .lmn-textarea,
body.citigpa .lmn-btn,
body.citigpa .lmn-dropdown-toggle,
body.citigpa h2,
body.citigpa h3,
body.citigpa h4,
body.citigpa h5,
body.citigpa h6,
body.citigpa-dev .lmn-font-overpass,
body.citigpa-dev .lmn-h2,
body.citigpa-dev .lmn-h3,
body.citigpa-dev .lmn-h4,
body.citigpa-dev .lmn-h5,
body.citigpa-dev .lmn-h6,
body.citigpa-dev .lmn-input,
body.citigpa-dev .lmn-textarea,
body.citigpa-dev .lmn-btn,
body.citigpa-dev .lmn-dropdown-toggle,
body.citigpa-dev h2,
body.citigpa-dev h3,
body.citigpa-dev h4,
body.citigpa-dev h5,
body.citigpa-dev h6 {
  font-family: Citi-Sans-Text-Regular !important;
}
body.citigpa .lmn-h1,
body.citigpa h1,
body.citigpa-dev .lmn-h1,
body.citigpa-dev h1 {
  font-family: Citi-Sans-Text-Regular;
  font-size: 44px;
  line-height: 60px;
}
body.citigpa .lmn-h2,
body.citigpa h2,
body.citigpa-dev .lmn-h2,
body.citigpa-dev h2 {
  font-size: 36px;
  line-height: 46px;
}
body.citigpa .lmn-h3,
body.citigpa h3,
body.citigpa-dev .lmn-h3,
body.citigpa-dev h3 {
  font-size: 32px;
  line-height: 40px;
}
body.citigpa .lmn-h4,
body.citigpa h4,
body.citigpa-dev .lmn-h4,
body.citigpa-dev h4 {
  font-size: 28px;
  line-height: 36px;
}
body.citigpa .lmn-h5,
body.citigpa h5,
body.citigpa-dev .lmn-h5,
body.citigpa-dev h5 {
  font-size: 24px;
  line-height: 30px;
}
body.citigpa .lmn-h6,
body.citigpa h6,
body.citigpa-dev .lmn-h6,
body.citigpa-dev h6 {
  font-size: 20px;
  line-height: 26px;
}
body.citigpa .h3-small,
body.citigpa-dev .h3-small {
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
}
body.citigpa .lmn-font-overpass-bold,
body.citigpa-dev .lmn-font-overpass-bold {
  font-family: Citi-Sans-Text-Regular !important;
}
body.citigpa .lmn-input,
body.citigpa .lmn-textarea,
body.citigpa .lmn-dropdown-toggle.lmn-btn,
body.citigpa .lmn-select-button .lmn-btn,
body.citigpa .lmn-select-trigger .lmn-select-button,
body.citigpa .lmn-select-input,
body.citigpa-dev .lmn-input,
body.citigpa-dev .lmn-textarea,
body.citigpa-dev .lmn-dropdown-toggle.lmn-btn,
body.citigpa-dev .lmn-select-button .lmn-btn,
body.citigpa-dev .lmn-select-trigger .lmn-select-button,
body.citigpa-dev .lmn-select-input {
  border-color: #BCC5C9 !important;
  border-radius: 0.375rem !important;
}
body.citigpa .lmn-input:focus, body.citigpa .lmn-input:active,
body.citigpa .lmn-textarea:focus,
body.citigpa .lmn-textarea:active,
body.citigpa .lmn-dropdown-toggle.lmn-btn:focus,
body.citigpa .lmn-dropdown-toggle.lmn-btn:active,
body.citigpa .lmn-select-button .lmn-btn:focus,
body.citigpa .lmn-select-button .lmn-btn:active,
body.citigpa .lmn-select-trigger .lmn-select-button:focus,
body.citigpa .lmn-select-trigger .lmn-select-button:active,
body.citigpa .lmn-select-input:focus,
body.citigpa .lmn-select-input:active,
body.citigpa-dev .lmn-input:focus,
body.citigpa-dev .lmn-input:active,
body.citigpa-dev .lmn-textarea:focus,
body.citigpa-dev .lmn-textarea:active,
body.citigpa-dev .lmn-dropdown-toggle.lmn-btn:focus,
body.citigpa-dev .lmn-dropdown-toggle.lmn-btn:active,
body.citigpa-dev .lmn-select-button .lmn-btn:focus,
body.citigpa-dev .lmn-select-button .lmn-btn:active,
body.citigpa-dev .lmn-select-trigger .lmn-select-button:focus,
body.citigpa-dev .lmn-select-trigger .lmn-select-button:active,
body.citigpa-dev .lmn-select-input:focus,
body.citigpa-dev .lmn-select-input:active {
  border-color: #255BE3 !important;
  -webkit-box-shadow: 0 0 0 0.125rem rgba(37, 91, 227, 0.4) !important;
  box-shadow: 0 0 0 0.125rem rgba(37, 91, 227, 0.4) !important;
}
body.citigpa .lmn-dropdown-toggle.lmn-btn.focus,
body.citigpa-dev .lmn-dropdown-toggle.lmn-btn.focus {
  -webkit-box-shadow: 0 0 0 0.125rem rgba(37, 91, 227, 0.4) !important;
  box-shadow: 0 0 0 0.125rem rgba(37, 91, 227, 0.4) !important;
}
body.citigpa .lmn-btn,
body.citigpa-dev .lmn-btn {
  border-radius: 0.375rem !important;
}
body.citigpa .lmn-select .lmn-menu-inline > .lmn-menu-item:not(.disabled):hover,
body.citigpa .lmn-dropdown-item:not(.disabled):hover,
body.citigpa-dev .lmn-select .lmn-menu-inline > .lmn-menu-item:not(.disabled):hover,
body.citigpa-dev .lmn-dropdown-item:not(.disabled):hover {
  background-color: #F0F5F7 !important;
}
body.citigpa .lmn-checkbox:not(.lmn-checkbox-native):not(.disabled):not(.invalid) .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-checkbox:not(.lmn-checkbox-native):not(.disabled):not(.invalid) .lmn-control-input:checked ~ .lmn-control-icon {
  background-color: #255BE3 !important;
}
body.citigpa .hpcbsm:checked,
body.citigpa-dev .hpcbsm:checked {
  accent-color: #255BE3 !important;
}
body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item.selected, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item:focus, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item.focus, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item:active, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item.active, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item:hover,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item.selected,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item:focus,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item.focus,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item:active,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item.active,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-single .lmn-menu-item:hover {
  background-color: #255BE3 !important;
  color: #fff !important;
}
body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover {
  background-color: #255BE3 !important;
  color: #fff !important;
}
body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-menu-item-inner, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus .lmn-menu-item-inner, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active .lmn-menu-item-inner, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus .lmn-menu-item-inner, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active .lmn-menu-item-inner, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover .lmn-menu-item-inner,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-menu-item-inner,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus .lmn-menu-item-inner,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active .lmn-menu-item-inner,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus .lmn-menu-item-inner,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active .lmn-menu-item-inner,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover .lmn-menu-item-inner {
  color: #fff !important;
}
body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-input:checked ~ .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus .lmn-control-input:checked ~ .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active .lmn-control-input:checked ~ .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus .lmn-control-input:checked ~ .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active .lmn-control-input:checked ~ .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover .lmn-control-input:checked ~ .lmn-control-icon {
  background-color: #FFFFFF !important;
}
body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active .lmn-control-icon, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover .lmn-control-icon {
  background-color: #FFFFFF !important;
}
body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-icon::before, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus .lmn-control-icon::before, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active .lmn-control-icon::before, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus .lmn-control-icon::before, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active .lmn-control-icon::before, body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover .lmn-control-icon::before,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-icon::before,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:focus .lmn-control-icon::before,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:active .lmn-control-icon::before,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.focus .lmn-control-icon::before,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.active .lmn-control-icon::before,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item:hover .lmn-control-icon::before {
  color: #fff !important;
  opacity: 1 !important;
}
body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-icon,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-icon {
  background-color: #FFFFFF !important;
}
body.citigpa .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-icon::before,
body.citigpa-dev .lmn-select:not(#OUTER_LAYER_CONTENT .lmn-select) .lmn-select-menu.lmn-select-menu-multiple .lmn-menu-item.selected .lmn-control-icon::before {
  color: #255BE3 !important;
  opacity: 1 !important;
}
body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover {
  background-color: #255BE3 !important;
  color: #fff !important;
}
body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-dropdown-item-inner, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-dropdown-item-inner, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-dropdown-item-inner, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-dropdown-item-inner,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-dropdown-item-inner,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-dropdown-item-inner,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-dropdown-item-inner,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-dropdown-item-inner {
  color: #fff !important;
}
body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-icon {
  background-color: #FFFFFF !important;
}
body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-icon::before {
  color: #FFFFFF !important;
  opacity: 1 !important;
}
body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:indeterminate ~ .lmn-control-icon:before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:hover ~ .lmn-control-icon:before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:indeterminate ~ .lmn-control-icon:before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:hover ~ .lmn-control-icon:before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:indeterminate ~ .lmn-control-icon:before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:hover ~ .lmn-control-icon:before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:indeterminate ~ .lmn-control-icon:before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:hover ~ .lmn-control-icon:before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:indeterminate ~ .lmn-control-icon:before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:hover ~ .lmn-control-icon:before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:indeterminate ~ .lmn-control-icon:before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:hover ~ .lmn-control-icon:before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:indeterminate ~ .lmn-control-icon:before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:hover ~ .lmn-control-icon:before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:indeterminate ~ .lmn-control-icon:before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:hover ~ .lmn-control-icon:before {
  color: #FFFFFF !important;
}
body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:indeterminate ~ .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:checked ~ .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:indeterminate ~ .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:checked ~ .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:indeterminate ~ .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:checked ~ .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:indeterminate ~ .lmn-control-icon, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:indeterminate ~ .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:indeterminate ~ .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:indeterminate ~ .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:checked ~ .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:indeterminate ~ .lmn-control-icon,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:checked ~ .lmn-control-icon {
  background-color: #FFFFFF !important;
}
body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:indeterminate ~ .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:checked ~ .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:indeterminate ~ .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:checked ~ .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:indeterminate ~ .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:checked ~ .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:indeterminate ~ .lmn-control-icon::before, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:checked ~ .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:indeterminate ~ .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:checked ~ .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:indeterminate ~ .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:checked ~ .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:indeterminate ~ .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:checked ~ .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:indeterminate ~ .lmn-control-icon::before,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:checked ~ .lmn-control-icon::before {
  color: #255BE3 !important;
  opacity: 1 !important;
}
body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:indeterminate ~ .lmn-control-icon:hover, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:checked ~ .lmn-control-icon:hover, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:indeterminate ~ .lmn-control-icon:hover, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:checked ~ .lmn-control-icon:hover, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:indeterminate ~ .lmn-control-icon:hover, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:checked ~ .lmn-control-icon:hover, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:indeterminate ~ .lmn-control-icon:hover, body.citigpa .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:checked ~ .lmn-control-icon:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:indeterminate ~ .lmn-control-icon:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:active .lmn-control-input:checked ~ .lmn-control-icon:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:indeterminate ~ .lmn-control-icon:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:focus .lmn-control-input:checked ~ .lmn-control-icon:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:indeterminate ~ .lmn-control-icon:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item.focus .lmn-control-input:checked ~ .lmn-control-icon:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:indeterminate ~ .lmn-control-icon:hover,
body.citigpa-dev .lmn-dropdown:not(#OUTER_LAYER_CONTENT .lmn-dropdown) .lmn-dropdown-menu .lmn-dropdown-item:hover .lmn-control-input:checked ~ .lmn-control-icon:hover {
  color: #255BE3 !important;
}

.icgds.citigpa .lmn-dropdown-menu-wrapper,
.icgds.citigpa-dev .lmn-dropdown-menu-wrapper {
  z-index: 90;
}

.icgds.citigpa a,
.icgds.citigpa-dev a,
.icgds.citigpa a:visited,
.icgds.citigpa-dev a:visited {
  color: #255BE3;
}

.icgds.citigpa a:focus,
.icgds.citigpa-dev a:focus {
  color: #255BE3;
}

.icgds.citigpa a:not(.not-default-link):hover,
.icgds.citigpa-dev a:not(.not-default-link):hover {
  color: #255BE3;
  text-decoration: underline;
}

body.icgds p {
  font-size: inherit;
}

.icgds.icgPublic a:visited {
  color: var(--link-color-0);
}

.icgds.cd-public a:visited {
  color: var(--link-color-0);
}

@media (max-width: 767px) {
  body.citigpa .lmn-h1,
  body.citigpa h1,
  body.citigpa-dev .lmn-h1,
  body.citigpa-dev h1 {
    font-size: 28px;
    line-height: 36px !important;
  }
  body.citigpa .lmn-h2,
  body.citigpa h2,
  body.citigpa-dev .lmn-h2,
  body.citigpa-dev h2 {
    font-size: 24px;
    line-height: 30px;
  }
  body.citigpa .lmn-h3,
  body.citigpa h3,
  body.citigpa-dev .lmn-h3,
  body.citigpa-dev h3 {
    font-size: 22px;
    line-height: 30px;
  }
  body.citigpa .lmn-h4,
  body.citigpa h4,
  body.citigpa-dev .lmn-h4,
  body.citigpa-dev h4 {
    font-size: 20px;
    line-height: 26px;
  }
  body.citigpa .lmn-h5,
  body.citigpa h5,
  body.citigpa-dev .lmn-h5,
  body.citigpa-dev h5 {
    font-size: 18px;
    line-height: 24px;
  }
  body.citigpa .lmn-h6,
  body.citigpa h6,
  body.citigpa-dev .lmn-h6,
  body.citigpa-dev h6 {
    font-size: 18px;
    line-height: 22px;
  }
}
.icgds.citigpa .lmn-dropdown-toggle.focus,
.icgds.citigpa .lmn-dropdown-toggle:focus:enabled:not(.disabled) {
  border: 1px solid #255Be3 !important;
}

.icgds.citigpa .lmn-select-input-text:focus,
.icgds.citigpa .lmn-select-input-text:focus-within {
  border-color: #255Be3 !important;
}

.icgds.citigpa .lmn-select-button:focus-within,
.icgds.citigpa .lmn-select-button:focus {
  border-color: #255Be3 !important;
}

.icgds.citigpa .lmn-select-input:focus,
.icgds.citigpa .lmn-select-input:focus-within {
  border-color: #255Be3 !important;
}

body.citigpa .lmn-select-input:focus {
  border-color: #255Be3 !important;
}

body.citigpa .lmn-input:focus-within,
body.citigpa .lmn-input:focus {
  border-color: #255Be3 !important;
}

.icgds.lmn-theme-light .citigroup-btn-white {
  color: #FFFFFF !important;
  background: transparent !important;
  border-color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-btn-white:focus {
  color: #FFFFFF !important;
  border: 1px solid #FFFFFF;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(115, 194, 252, 0.4);
  box-shadow: 0px 0px 0px 2px rgba(115, 194, 252, 0.4);
}
.icgds.lmn-theme-light .citigroup-btn-white:hover {
  color: #FFFFFF !important;
  background: #0F1632 !important;
  border-color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-btn-white:active {
  color: #255BE3 !important;
  background: #FFFFFF !important;
  border-color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-btn-white:disabled {
  color: #A4ACAF !important;
  background: transparent !important;
  border-color: transparent !important;
}

.icgds.lmn-theme-light .citigroup-btn-white-ink-blue {
  color: #FFFFFF !important;
  background: transparent !important;
  border-color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:focus {
  color: #FFFFFF !important;
  border: 1px solid #FFFFFF;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(115, 194, 252, 0.4);
  box-shadow: 0px 0px 0px 2px rgba(115, 194, 252, 0.4);
}
.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:hover {
  color: #FFFFFF !important;
  background: #255BE3 !important;
  border-color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:active {
  color: #255BE3 !important;
  background: #FFFFFF !important;
  border-color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:disabled {
  color: #A4ACAF !important;
  background: transparent !important;
  border-color: transparent !important;
}

.citigpa .lmn-btn-secondary,
.citigpa-dev .lmn-btn-secondary {
  color: #255BE3 !important;
  border-color: #255BE3 !important;
}

.citigpa .lmn-btn-primary,
.citigpa-dev .lmn-btn-primary {
  color: #FFFFFF !important;
  background: #255BE3 !important;
}

.citigpa .lmn-btn-primary-dark,
.citigpa-dev .lmn-btn-primary-dark {
  color: #FFFFFF !important;
  background: #255BE3 !important;
}

body.citigpa.icgds .lmn-btn-primary:hover,
body.citigpa-dev.icgds .lmn-btn-primary:hover {
  color: #FFFFFF !important;
  background-color: #233FAA !important;
}
body.citigpa.icgds .lmn-btn-primary:focus, body.citigpa.icgds .lmn-btn-secondary:focus,
body.citigpa-dev.icgds .lmn-btn-primary:focus,
body.citigpa-dev.icgds .lmn-btn-secondary:focus {
  color: #FFFFFF !important;
  border: 2px solid #FFFFFF !important;
  background: #255BE3 !important;
  -webkit-box-shadow: 0 0 0 2px #255BE3 !important;
  box-shadow: 0 0 0 2px #255BE3 !important;
}
body.citigpa.icgds .lmn-btn-primary:active, body.citigpa.icgds .lmn-btn-secondary:active,
body.citigpa-dev.icgds .lmn-btn-primary:active,
body.citigpa-dev.icgds .lmn-btn-secondary:active {
  color: #FFFFFF !important;
  background-color: #0F1632 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
}
body.citigpa.icgds .lmn-btn-secondary:hover,
body.citigpa-dev.icgds .lmn-btn-secondary:hover {
  color: #FFFFFF !important;
  background-color: #255BE3 !important;
  border-color: #255BE3 !important;
}
body.citigpa.icgds .lmn-btn-primary-dark:hover,
body.citigpa-dev.icgds .lmn-btn-primary-dark:hover {
  color: #FFFFFF !important;
  background: #255BE3 !important;
  border: 1px solid #FFFFFF !important;
}
body.citigpa.icgds .lmn-btn-primary-dark:focus,
body.citigpa-dev.icgds .lmn-btn-primary-dark:focus {
  color: #FFFFFF !important;
  border: 2px solid #FFFFFF !important;
  background-color: #255BE3 !important;
  -webkit-box-shadow: 0 0 0 2px #255BE3 !important;
  box-shadow: 0 0 0 2px #255BE3 !important;
  outline: none !important;
}
body.citigpa.icgds .lmn-btn-primary-dark:active,
body.citigpa-dev.icgds .lmn-btn-primary-dark:active {
  color: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  border-color: #255BE3 !important;
  background: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
body.citigpa.icgds.lmn-theme-light .citigroup-btn-white-ink-blue,
body.citigpa-dev.icgds.lmn-theme-light .citigroup-btn-white-ink-blue {
  color: #FFFFFF !important;
  background: transparent !important;
  border-color: #FFFFFF !important;
}
body.citigpa.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:hover,
body.citigpa-dev.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:hover {
  color: #FFFFFF !important;
  background: #255BE3 !important;
  border-color: #FFFFFF !important;
}
body.citigpa.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:focus,
body.citigpa-dev.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:focus {
  color: #FFFFFF !important;
  border: 2px solid #FFFFFF;
  background-color: #255BE3 !important;
  -webkit-box-shadow: 0 0 0 2px #255BE3 !important;
  box-shadow: 0 0 0 2px #255BE3 !important;
  outline: none !important;
}
body.citigpa.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:active,
body.citigpa-dev.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:active {
  color: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  border-color: #255BE3 !important;
  background: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
body.citigpa.icgds .lmn-btn-primary:disabled, body.citigpa.icgds .lmn-btn-primary-dark:disabled, body.citigpa.icgds .lmn-btn-secondary:disabled, body.citigpa.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:disabled,
body.citigpa-dev.icgds .lmn-btn-primary:disabled,
body.citigpa-dev.icgds .lmn-btn-primary-dark:disabled,
body.citigpa-dev.icgds .lmn-btn-secondary:disabled,
body.citigpa-dev.icgds.lmn-theme-light .citigroup-btn-white-ink-blue:disabled {
  color: #A4ACAF !important;
  background: #E6EBED !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  opacity: 1;
  cursor: not-allowed !important;
}

.icgds.lmn-theme-light .citigroup-link-text {
  color: #000000 !important;
}
.icgds.lmn-theme-light .citigroup-link-text:focus {
  color: #255BE3 !important;
  text-decoration: underline;
}
.icgds.lmn-theme-light .citigroup-link-text:hover {
  color: #255BE3 !important;
  text-decoration: underline;
}
.icgds.lmn-theme-light .citigroup-link-text:active {
  color: #0041A5 !important;
  text-decoration: underline;
}

.icgds.lmn-theme-light .citigroup-link-icon {
  color: #255BE3 !important;
}
.icgds.lmn-theme-light .citigroup-link-icon:focus {
  color: #255BE3 !important;
}
.icgds.lmn-theme-light .citigroup-link-icon:hover {
  color: #255BE3 !important;
}
.icgds.lmn-theme-light .citigroup-link-icon:active {
  color: #0041A5 !important;
}

.icgds.lmn-theme-light .citigroup-link-text-inverse {
  color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-link-text-inverse:focus {
  color: #FFFFFF !important;
  text-decoration: underline;
}
.icgds.lmn-theme-light .citigroup-link-text-inverse:hover {
  color: #FFFFFF !important;
  text-decoration: underline;
}
.icgds.lmn-theme-light .citigroup-link-text-inverse:active {
  color: #FFFFFF !important;
  text-decoration: underline;
}

.icgds.lmn-theme-light .citigroup-link-icon-inverse {
  color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-link-icon-inverse:focus {
  color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-link-icon-inverse:hover {
  color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-link-icon-inverse:active {
  color: #FFFFFF !important;
}

.icgds.lmn-theme-light .citigroup-link-text-0 {
  color: #255BE3;
}
.icgds.lmn-theme-light .citigroup-link-text-0:focus {
  color: #255BE3 !important;
  text-decoration: underline;
}
.icgds.lmn-theme-light .citigroup-link-text-0:hover {
  color: #255BE3 !important;
  text-decoration: underline;
}
.icgds.lmn-theme-light .citigroup-link-text-0:active {
  color: #0041A5 !important;
  text-decoration: underline;
}

.icgds.lmn-theme-light .citigroup-limit-1line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icgds.lmn-theme-light .citigroup-limit-2lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-2lines sub, .icgds.lmn-theme-light .citigroup-limit-2lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-3lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-3lines sub, .icgds.lmn-theme-light .citigroup-limit-3lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-4lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-4lines sub, .icgds.lmn-theme-light .citigroup-limit-4lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-5lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-5lines sub, .icgds.lmn-theme-light .citigroup-limit-5lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-6lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-6lines sub, .icgds.lmn-theme-light .citigroup-limit-6lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-7lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-7lines sub, .icgds.lmn-theme-light .citigroup-limit-7lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-8lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 8;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-8lines sub, .icgds.lmn-theme-light .citigroup-limit-8lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-9lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 9;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-9lines sub, .icgds.lmn-theme-light .citigroup-limit-9lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-10lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 10;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-10lines sub, .icgds.lmn-theme-light .citigroup-limit-10lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-11lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 11;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-11lines sub, .icgds.lmn-theme-light .citigroup-limit-11lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-limit-12lines {
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 12;
  max-height: auto;
}
.icgds.lmn-theme-light .citigroup-limit-12lines sub, .icgds.lmn-theme-light .citigroup-limit-12lines sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.icgds.lmn-theme-light .citigroup-theme-blue-200 {
  background-color: #255BE3 !important;
  color: #FFFFFF !important;
}

.icgds.lmn-theme-light .citigroup-theme-blue-500 {
  background-color: #0F1632 !important;
  color: #FFFFFF !important;
}

.icgds.lmn-theme-light .citigroup-theme-citi-blue {
  background-color: #0F1632 !important;
  color: #FFFFFF !important;
}
.icgds.lmn-theme-light .citigroup-theme-citi-blue:not(.no-inherit-link) a,
.icgds.lmn-theme-light .citigroup-theme-citi-blue:not(.no-inherit-link) .link-target {
  color: var(--white) !important;
  text-decoration: underline !important;
}
.icgds.lmn-theme-light .citigroup-theme-citi-blue:not(.no-inherit-link) a:hover,
.icgds.lmn-theme-light .citigroup-theme-citi-blue:not(.no-inherit-link) .link-target:hover {
  color: #73c2fc !important;
}

.icgds.lmn-theme-light .citigroup-theme-gray-075 {
  background-color: #F0F5F7 !important;
  color: #000000 !important;
}

.icgds.lmn-theme-light .citigroup-theme-transparent {
  background-color: transparent !important;
  color: #000000 !important;
}

.citigroup-container-full {
  max-width: 100%;
  width: 1920px;
  margin: auto;
}

.citigroup-container-sm-center {
  max-width: 776px;
  margin: auto;
}
@media (max-width: 1299.98px) {
  .citigroup-container-sm-center {
    max-width: 56%;
  }
}
@media (max-width: 768px) {
  .citigroup-container-sm-center {
    max-width: 90%;
  }
}

.icgds.lmn-theme-light .citigroup-h1 {
  font-size: 44px !important;
  line-height: 60px !important;
  font-weight: 400 !important;
}

.icgds.lmn-theme-light .citigroup-h2 {
  font-size: 36px !important;
  line-height: 46px !important;
  font-weight: 400 !important;
}

.icgds.lmn-theme-light .citigroup-h3 {
  font-size: 32px !important;
  line-height: 40px !important;
  font-weight: 400 !important;
}

.icgds.lmn-theme-light .citigroup-h4 {
  font-size: 28px !important;
  line-height: 36px !important;
  font-weight: 400 !important;
}

.icgds.lmn-theme-light .citigroup-h5 {
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 400 !important;
}

.icgds.lmn-theme-light .citigroup-h6 {
  font-size: 20px !important;
  line-height: 26px !important;
  font-weight: 400 !important;
}

.icgds.lmn-theme-light .citigroup-subHeading {
  font-size: 28px !important;
  line-height: 36px !important;
  font-weight: 400 !important;
}

.ck-content figure.table table.remove-vertical-border tr {
  border-bottom: 1px solid #c8d5e1;
}
.ck-content figure.table table.remove-vertical-border th,
.ck-content figure.table table.remove-vertical-border td {
  border: transparent;
}

.ck-content .gpa-editor figure.table table.add-vertical-border th,
.ck-content .gpa-editor figure.table table.add-vertical-border td {
  border: 1px solid hsl(0, 0%, 75%);
}

@media (max-width: 767px) {
  .icgds.lmn-theme-light .citigroup-h1 {
    font-size: 28px !important;
    line-height: 36px !important;
    font-weight: 400 !important;
  }
  .icgds.lmn-theme-light .citigroup-h2 {
    font-size: 24px !important;
    line-height: 30px !important;
    font-weight: 400 !important;
  }
  .icgds.lmn-theme-light .citigroup-h3 {
    font-size: 22px !important;
    line-height: 30px !important;
    font-weight: 400 !important;
  }
  .icgds.lmn-theme-light .citigroup-h4 {
    font-size: 20px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
  }
  .icgds.lmn-theme-light .citigroup-h5 {
    font-size: 18px !important;
    line-height: 24px !important;
    font-weight: 400 !important;
  }
  .icgds.lmn-theme-light .citigroup-h6 {
    font-size: 18px !important;
    line-height: 22px !important;
    font-weight: 400 !important;
  }
  .icgds.lmn-theme-light .citigroup-subHeading {
    font-size: 20px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
  }
}
@media screen and (min-width: 768px) {
  .icgds.lmn-theme-light .citigroup-fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  }
  .icgds.lmn-theme-light .citigroup-fadeInLeft:nth-of-type(1) {
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .icgds.lmn-theme-light .citigroup-fadeInLeft:nth-of-type(2) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .icgds.lmn-theme-light .citigroup-fadeInLeft:nth-of-type(3) {
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }
  .icgds.lmn-theme-light .citigroup-fadeInLeft:nth-of-type(4) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  .icgds.lmn-theme-light .citigroup-fadeInLeft:nth-of-type(5) {
    -webkit-animation-delay: 1.25s;
    animation-delay: 1.25s;
  }
  .icgds.lmn-theme-light .citigroup-fadeInLeft:nth-of-type(6) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
  }
  .icgds.lmn-theme-light .citigroup-2fadecards:nth-of-type(2n + 1) {
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .icgds.lmn-theme-light .citigroup-2fadecards:nth-of-type(2n + 2) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .icgds.lmn-theme-light .citigroup-3fadecards:nth-of-type(3n + 1) {
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .icgds.lmn-theme-light .citigroup-3fadecards:nth-of-type(3n + 2) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .icgds.lmn-theme-light .citigroup-3fadecards:nth-of-type(3n + 3) {
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }
  .icgds.lmn-theme-light .citigroup-4fadecards:nth-of-type(4n + 1) {
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .icgds.lmn-theme-light .citigroup-4fadecards:nth-of-type(4n + 2) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .icgds.lmn-theme-light .citigroup-4fadecards:nth-of-type(4n + 3) {
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }
  .icgds.lmn-theme-light .citigroup-4fadecards:nth-of-type(4n + 4) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  .icgds.lmn-theme-light .citigroup-fadeInUp {
    opacity: 1;
  }
  .icgds.lmn-theme-light .citigroup-animatedFadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @-webkit-keyframes fadeInUp {
    from {
      -webkit-transform: translate3d(0, 40px, 0);
      transform: translate3d(0, 40px, 0);
      opacity: 0;
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes fadeInUp {
    from {
      -webkit-transform: translate3d(0, 40px, 0);
      transform: translate3d(0, 40px, 0);
      opacity: 0;
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  .icgds.lmn-theme-light .citigroup-animatedFadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInDown {
    from {
      -webkit-transform: translate3d(0, -40px, 0);
      transform: translate3d(0, -40px, 0);
      opacity: 0;
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes fadeInDown {
    from {
      -webkit-transform: translate3d(0, -40px, 0);
      transform: translate3d(0, -40px, 0);
      opacity: 0;
    }
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  span.citigroup-fadeInUp, sub.citigroup-fadeInUp, strong.citigroup-fadeInUp, img.citigroup-fadeInUp, a.citigroup-fadeInUp, b.citigroup-fadeInUp, u.citigroup-fadeInUp, sub.citigroup-fadeInUp, sup.citigroup-fadeInUp, label.citigroup-fadeInUp, em.citigroup-fadeInUp, br.citigroup-fadeInUp, font.citigroup-fadeInUp, strike.citigroup-fadeInUp {
    display: inline-block;
  }
  span.citigroup-animatedFadeInUp, sub.citigroup-animatedFadeInUp, strong.citigroup-animatedFadeInUp, img.citigroup-animatedFadeInUp, a.citigroup-animatedFadeInUp, b.citigroup-animatedFadeInUp, u.citigroup-animatedFadeInUp, sub.citigroup-animatedFadeInUp, sup.citigroup-animatedFadeInUp, label.citigroup-animatedFadeInUp, em.citigroup-animatedFadeInUp, br.citigroup-animatedFadeInUp, font.citigroup-animatedFadeInUp, strike.citigroup-animatedFadeInUp {
    display: inline-block;
  }
}
@media screen and (max-width: 767px) {
  .icgds.lmn-theme-light .citigroup-animatedFadeInUp {
    opacity: 1 !important;
    -webkit-transition: none;
    transition: none;
  }
}
.gpa-editor {
  font-weight: 400;
  color: #000000;
}

.gpa-editor h1 {
  font-size: 44px;
  line-height: 60px;
}

.gpa-editor h2 {
  font-size: 36px;
  line-height: 46px;
}

.gpa-editor h3 {
  font-size: 32px;
  line-height: 40px;
}

.gpa-editor h4 {
  font-size: 28px;
  line-height: 36px;
}

.gpa-editor h5 {
  font-size: 24px;
  line-height: 30px;
}

.gpa-editor h6 {
  font-size: 20px;
  line-height: 26px;
}

.gpa-editor p {
  font-size: 18px;
  line-height: 22px;
}

.gpa-editor h1,
.gpa-editor h2,
.gpa-editor h3,
.gpa-editor h4,
.gpa-editor h5,
.gpa-editor h6 {
  color: #000000;
  font-weight: 400;
}

.gpa-editor table {
  border: transparent;
  color: #22303E;
  line-height: 18px;
  max-width: 100%;
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  height: auto !important;
  font-size: 14px;
}

.gpa-editor figure.table {
  border: transparent;
  color: #22303E;
  line-height: 18px;
  max-width: 100%;
  display: block;
  overflow-x: auto;
  overflow-y: hidden;
  height: auto !important;
  font-size: 14px;
}

.gpa-editor figure.table table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: transparent;
  color: #22303E;
  max-width: 100%;
  overflow-x: auto;
  height: auto !important;
}

.ck-content .gpa-editor figure.table table td,
.ck-content .gpa-editor figure.table table th {
  border: transparent;
}

.gpa-editor figure.table table th {
  background-color: #F0F4F7;
}

.gpa-editor figure.table table tbody > :last-child {
  background-color: transparent;
}

.gpa-editor figure.table table thead tr {
  background-color: transparent;
}

.gpa-editor table td,
.gpa-editor table th {
  padding: 8px;
  vertical-align: top;
}

.ck-content .gpa-editor figure.table table td,
.ck-content .gpa-editor figure.table table th {
  padding: 8px;
}

.gpa-editor figure.table table tr {
  border-bottom: 1px solid #C8D5E1;
}

.gpa-editor figure.table table tbody tr:last-child {
  border-bottom: transparent;
}

.gpa-editor table tbody tr {
  border-bottom: 1px solid #C8D5E1;
}

.gpa-editor table tbody tr:last-child {
  border-bottom: transparent;
}

.gpa-editor table tbody > :first-child {
  background-color: #F0F4F7;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.gpa-editor a {
  color: #005FF1;
}

.gpa-editor table a {
  font-size: 14px;
  color: #005FF1;
}

@media (max-width: 767px) {
  .gpa-editor h1 {
    font-size: 28px;
    line-height: 36px;
  }
  .gpa-editor h2 {
    font-size: 24px;
    line-height: 30px;
  }
  .gpa-editor h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .gpa-editor h4 {
    font-size: 20px;
    line-height: 26px;
  }
  .gpa-editor h5 {
    font-size: 18px;
    line-height: 24px;
  }
  .gpa-editor h6 {
    font-size: 18px;
    line-height: 22px;
  }
  .gpa-editor p {
    font-size: 14px;
    line-height: 18px;
  }
}
/*# sourceMappingURL=src\siteStyle\dist\citigpa\citigpa.css.map */